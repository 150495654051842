import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
        <div>
      <div dangerouslySetInnerHTML={{ __html: `

<nav class="navbar navbar-light light-blue lighten-4 d-sm-none">

    <a class="navbar-brand" href="/">
    <img src="/uploads/voorlichtingsles-logo.png" height="40" alt="Voorlichtingsles Volwassen Worden">
  </a>
    
  
  <button class="navbar-toggler toggler-example" type="button" data-toggle="collapse" data-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
      <span class="dark-blue-text"><i class="fas fa-bars fa-1x"></i></span>
    </button>

  
  <div class="collapse navbar-collapse" id="navbarSupportedContent1">

    
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link waves-effect waves-light" href="/">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" href="/lessen">Lessen</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" href="/lesmaterialen">Lesmaterialen</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" href="/multimedia">Multimedia aanpak</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" href="/lesmaterialen/lespakket-gratis-bestellen">Lespakket gratis bestellen</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" href="/service">Service</a>
      </li>
    </ul>
    

  </div>
  

</nav>

    <header class="bg-blue d-none d-sm-block pt-3">

        <div class="container-fluid" style="border-bottom:rgb(238,38,110) 7px solid">
            <div class="container">
                <div class="row hideMegaMenu">
                    <div class="col-12">
                        <img src="/uploads/voorlichtingsles-logo.png" alt="Voorlichtingsles Volwassen Worden">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <a class="show-menu" href="/" data-value="-1"><i class="fas fa-home fa-2x"></i></a>
                        <a class="show-menu" href="/lessen" data-value="0">Lessen</a>
                        <a class="show-menu" href="/lesmaterialen" data-value="1">Lesmaterialen</a>
                        <a class="show-menu" href="/multimedia" data-value="2">Multimedia aanpak</a>
                        <a class="show-menu" href="/lesmaterialen/lespakket-gratis-bestellen" data-value="3">Lespakket gratis bestellen</a>
                        <a class="show-menu" href="/service" data-value="4">Service</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="megamenu container-fluid" data-show="0" style="z-index:99999;position:absolute;display:none">
            <div id="megamenu_0" class="container">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-md-3">
                            <img src="/uploads/layer_1_richtlijnen.jpg" class="img-fluid" alt="Richtlijnen">
                                <a href="/lessen/richtlijnen" class="d-block mt-1 tab-menu stretched-link">Richtlijnen voor de seksuele voorlichting</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_1_lesvoorbereiding.jpg" class="img-fluid" alt="Lesvoorbereiding">
                                <a href="/lessen/lesvoorbereiding" class="d-block mt-1 tab-menu stretched-link">Lesvoorbereiding</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_1_tips.jpg" class="img-fluid" alt="Tips">
                                <a href="/lessen/tips" class="d-block mt-1 tab-menu stretched-link">Tips voor de seksuele voorlichtingslessen</a>
                        </div>
                    </div>
                </div>
                <div class="container-fluid borderd"></div>
            </div>
            <div id="megamenu_1" class="container">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-md-4">
                            <ul class="nav navigatie">
                                <li class="font-weight-bold"><a href="/lesmaterialen/themagebieden" style="color:inherit">Themagebieden</a>
                                    <div>
                                        <a href="/lesmaterialen/themagebieden/ontwikkeling-en-puberteit" class="d-block mt-1 tab-menu">Ontwikkeling en puberteit</a>
                                        <a href="/lesmaterialen/themagebieden/geslachtsorganen" class="d-block mt-1 tab-menu">Geslachtsorganen</a>
                                        <a href="/lesmaterialen/themagebieden/cyclus-en-menstruatie" class="d-block mt-1 tab-menu">Menstruatiecyclus</a>
                                        <a href="/lesmaterialen/themagebieden/menstruatie-hygine" class="d-block mt-1 tab-menu">Menstruatie hygi&#xEB;ne</a>
                                        <a href="/lesmaterialen/themagebieden/tampongebruik" class="d-block mt-1 tab-menu">Tampongebruik</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-4">
                            <ul class="nav navigatie">
                                <li class="font-weight-bold"><a href="/lesmaterialen/speciale-pakketten" style="color:inherit">Speciale pakketten</a>
                                    <div>
                                        <a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-meisjes" class="d-block mt-1 tab-menu">Lesmateriaal voor meisjes</a>
                                        <a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens" class="d-block mt-1 tab-menu">Lesmateriaal voor jongens</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-4">

                            <ul class="nav navigatie">
                                <li class="font-weight-bold"><a href="/lesmaterialen/speciale-pakketten" style="color:inherit">Lespakket gratis bestellen</a>
                                    <div>
                                        <a href="/lesmaterialen/lespakket-gratis-bestellen/docentenhandleiding" class="d-block mt-1 tab-menu">Docentenhandleiding</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="container-fluid borderd"></div>
            </div>
            <div id="megamenu_2" class="container">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-md-3">
                            <img src="/uploads/layer_3_instructiefilm.jpg" class="img-fluid" alt="Instructiefilm">
                                <a href="/multimedia/instructiefilm-over-seksuele-voorlichting-volwassen-worden" class="d-block mt-1 tab-menu stretched-link">Instructiefilm over seksuele voorlichting &#xAB;Volwassen worden&#xBB;</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_3_kennistest.jpg" class="img-fluid" alt="Online kennistest">
                                <a href="/multimedia/online-kennistests" class="d-block mt-1 tab-menu stretched-link">Online kennistest</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_3_oefeningen.jpg" class="img-fluid" alt="Oefenbladen">
                                <a href="/multimedia/oefenbladen" class="d-block mt-1 tab-menu stretched-link">Oefenbladen</a>
                        </div>
                    </div>
                </div>
                <div class="container-fluid borderd"></div>
            </div>
            <div id="megamenu_3" class="container">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-md-3">
                            <img src="/uploads/layer_4_primaironderwijs.jpg" class="img-fluid" alt="Bestel nu gratis">
                                <a href="/lesmaterialen/lespakket-gratis-bestellen" class="d-block mt-1 tab-menu stretched-link">Bestel nu gratis</a>
                        </div>
                    </div>
                </div>
                <div class="container-fluid borderd"></div>
            </div>
            <div id="megamenu_4" class="container">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-md-3">
                            <img src="/uploads/layer_5_belangrijke_vragen.jpg" class="img-fluid" alt="Belangrijke vragen">
                                <a href="/service/veel-gestelde-vragen" class="d-block mt-1 tab-menu stretched-link">Belangrijke vragen over menstruatie en menstruatiehygi&#xEB;ne</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_5_belangrijke_links.jpg" class="img-fluid" alt="Service">
                                <a href="/service/belangrijke-links" class="d-block mt-1 tab-menu stretched-link">Belangrijke links</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_5_contact.jpg" class="img-fluid" alt="Contact">
                                <a href="/service/contact" class="d-block mt-1 tab-menu stretched-link">Contact</a>
                        </div>
                    </div>
                </div>
                <div class="container-fluid borderd"></div>
            </div>
        </div>
    </header>
    
    <main class="hideMegaMenu">
        <div class="container-fluid opening mb-3">            
            <div class="container bg-licht-groen">
                <div class="row">
                    <div class="col-md-6 px-4 py-3">
                        <h1 class="h1-responsive text-white">Oefenbladen</h1>

                    </div>
                    <div class="col-md-6">
                        <img src="/uploads/img_4457_3_multimedia.jpg" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid hideMegaMenu">
            <div class="container">

	<div id="breadcrumb"><div class="bc my-3 d-none d-sm-block ">
	<a href="/">Home</a> &gt; <a href="/multimedia">Multimedia voor seksuele voorlichting</a> &gt; <a href="/multimedia/oefenbladen">Oefenbladen</a>
</div>
</div>

                <div class="row">
                    <div class="col-md-8">
                        <div id="content">
		<h1 class="h1-responsive my-3  ">Oefenbladen</h1>
         <p class="lead hide"></p>
        <p></p><p>De leerlingen kunnen aan de hand van deze pdf&#x2019;s hun kennis testen. Oefeningen over de plek en de namen van de vrouwelijke en mannelijke geslachtsorganen zijn beschikbaar, evenals het omgaan met de berekening van de cyclus met betrekking tot de volgende menstruatie.</p>

<div class="card">
    <div class="card-body">
        <h5 class="card-title">
            Hier alle documenten bij het themagebied met een klik te downloaden.</h5>
        <a href="/" class="btn bg-blauw text-white waves-effect waves-light">Download</a>
    </div>
</div>

<div class="B00562">
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/oefening-de-vrouwelijke-geslachtsorganen-4631.png" class="img-fluid" alt="Oefening" “de vrouwelijke geslachtsorganen”>
    </div>
    <div class="col-md-6">
        <h5>Oefening &#x201C;de vrouwelijke geslachtsorganen&#x201D;</h5>
        <p>Behulpzaam materiaal: in quizvorm kunnen de leerlingen aan de hand van pdf&#x2019;s hun kennis van de vrouwelijke geslachtsorganen oefenen.
</p>
    </div>
    <div class="col-md-3 ">
	<a href="/uploads/oefening-de-vrouwelijke-geslachtsorganen-4631.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/oplossing-de-vrouwelijke-geslachtsorganen-4632.png" class="img-fluid" alt="Oplossing" “de vrouwelijke geslachtsorganen”>
    </div>
    <div class="col-md-6">
        <h5>Oplossing &#x201C;de vrouwelijke geslachtsorganen&#x201D;
</h5>
        <p>Oplossingen van de oefening &#x201C;de vrouwelijke geslachtsorganen&#x201D;.</p>
    </div>
    <div class="col-md-3 ">
	<a href="/uploads/oplossing-de-vrouwelijke-geslachtsorganen-4632.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/oefening-de-mannelijke-geslachtsorganen-4633.png" class="img-fluid" alt="Oefening:" “de mannelijke geslachtsorganen”>
    </div>
    <div class="col-md-6">
        <h5>Oefening: &#x201C;de mannelijke geslachtsorganen&#x201D;</h5>
        <p>Lesmateriaal voor de benoeming van de mannelijke geslachtsorganen (in voor- en zij aanzicht)
</p>
    </div>
    <div class="col-md-3 ">
	<a href="/uploads/oefening-de-mannelijke-geslachtsorganen-4633.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/oplossing-de-mannelijke-geslachtsorganen-4634.png" class="img-fluid" alt="Oplossing:" “de mannelijke geslachtsorganen”>
    </div>
    <div class="col-md-6">
        <h5>Oplossing: &#x201C;de mannelijke geslachtsorganen&#x201D;</h5>
        <p>Oplossingsblad voor de benoeming van de mannelijke geslachtsorganen (in voor- en zij aanzicht) voor de seksuele voorlichtingslessen.
</p>
    </div>
    <div class="col-md-3 ">
	<a href="/uploads/oplossing-de-mannelijke-geslachtsorganen-4634.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/oefenblad-cycluskalender-4641.png" class="img-fluid" alt="Oefenblad" cycluskalender>
    </div>
    <div class="col-md-6">
        <h5>Oefenblad cycluskalender</h5>
        <p>De leerlingen kunnen aan de hand van deze pdf hun cyclus berekenen met betrekking tot de volgende menstruatie.</p>
    </div>
    <div class="col-md-3 ">
	<a href="/uploads/oefenblad-cycluskalender-4641.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
<div class="row mt-3 mb-5">
    <div class="col-md-3"><img src="/uploads/oplossingsblad-cycluskalender-4642.png" class="img-fluid" alt="Oplossingsblad" cycluskalender>
    </div>
    <div class="col-md-6">
        <h5>Oplossingsblad cycluskalender</h5>
        <p>Oplossingsblad voor de Quiz cycluskalender</p>
    </div>
    <div class="col-md-3 ">
	<a href="/uploads/oplossingsblad-cycluskalender-4642.pdf" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">PDF openen</a>
    </div>
</div>
</div>

<h3>Meer informatie</h3>
<p>
    <a href="/multimedia/instructiefilm-over-seksuele-voorlichting-volwassen-worden">Introductiefilm &quot;Volwassen worden&quot;</a><br>
    <a href="/service/veel-gestelde-vragen">Veel voorkomende vragen over de menstruatie en de menstruatie hygi&#xEB;ne</a>
</p><p></p>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="bg-blue zij-menu p-3">
	<a href="/multimedia" class="level-1 d-block ">Multimedia voor seksuele voorlichting</a>
<a href="/multimedia/instructiefilm-over-seksuele-voorlichting-volwassen-worden" class="level-2 d-block ">Instructiefilm over seksuele voorlichting &quot;Volwassen worden&quot;</a>
<a href="/multimedia/online-kennistests" class="level-2 d-block ">Online kennistests</a>
<a href="/multimedia/online-kennistests/quiz-puberteit" class="level-3 d-block ">Quiz puberteit</a>
<a href="/multimedia/online-kennistests/quiz-menstruatie" class="level-3 d-block ">Quiz menstruatie</a>
<a href="/multimedia/online-kennistests/quiz-menstruatie-hygiene" class="level-3 d-block ">Quiz tampons en maandverband</a>
<a href="/multimedia/oefenbladen" class="level-2 d-block text-rood">Oefenbladen</a>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </main>


    
<div class="B00544">
    <div class="container-fluid pb-3">
        <div class="container">
            <div class="row mr-md-5 pr-md-5">
		<div class="col-md-4 text-center ">
    <a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens" class="stretched-link"><img src="/uploads/callout1_jongen.png" class="img-fluid p-3" alt="Materiaal voor jongens"></a>
    <h3>Materiaal voor jongens</h3>
    <div class="pay-off">
        Lesmateriaal rondom het thema puberteit bij jongens
    </div>
    <a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Nu downloaden</a>
</div>
<div class="col-md-4 text-center d-none">
    <a href="/lesmaterialen/speciale-pakketten" class="stretched-link"><img src="/uploads/callout2_primair_onderwijs.png" class="img-fluid p-3" alt="Materiaal voor basisscholen"></a>
    <h3>Materiaal voor basisscholen</h3>
    <div class="pay-off">
        Lesmateriaal voor seksuele voorlichtingslessen
    </div>
    <a href="/lesmaterialen/speciale-pakketten" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Nu samenstellen</a>
</div>
<div class="col-md-4 text-center d-none">
    <a href="/lessen/lesvoorbereiding" class="stretched-link"><img src="/uploads/callout3_lesvoorbereiding.png" class="img-fluid p-3" alt="Hulp bij de lesvoorbereiding"></a>
    <h3>Hulp bij de lesvoorbereiding</h3>
    <div class="pay-off">
        Tips en checklists voor seksuele voorlichtingslessen
    </div>
    <a href="/lessen/lesvoorbereiding" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Nu informeren</a>
</div>
<div class="col-md-4 text-center ">
    <a href="/lesmaterialen" class="stretched-link"><img src="/uploads/callout5_lesmateriaal.png" class="img-fluid p-3" alt="Download lesmaterialen"></a>
    <h3>Download lesmaterialen</h3>
    <div class="pay-off">
        Uitgebreid aanbod van onderwerpen voor de seksuele voorlichting 
    </div>
    <a href="/lesmaterialen" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Naar de materialen</a>
</div>
<div class="col-md-4 text-center d-none">
    <a href="/multimedia" class="stretched-link"><img src="/uploads/callout6_multimedia.png" class="img-fluid p-3" alt="Multimedia voor seksuele voorlichting"></a>
    <h3>Multimedia voor seksuele voorlichting</h3>
    <div class="pay-off">
        Instructiefilm en interactieve kennistests voor seksuele voorlichting
    </div>
    <a href="/multimedia" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Nu bekijken</a>
</div>
<div class="col-md-4 text-center ">
    <a href="/lesmaterialen/lespakket-gratis-bestellen" class="stretched-link"><img src="/uploads/callout4_lespakket_bestellen.png" class="img-fluid p-3" alt="Gratis lespakket"></a>
    <h3>Gratis lespakket</h3>
    <div class="pay-off">
        Relevante lesmaterialen voor seksuele voorlichting
    </div>
    <a href="/lesmaterialen/lespakket-gratis-bestellen" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Nu bestellen</a>
</div>
            </div>
        </div>
    </div>
</div>

        ` }} />
            <Footer></Footer>  
        </div>   


    );
  }
}

export default Page;

